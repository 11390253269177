import { json, redirect } from "@remix-run/node";
import { useLoaderData } from "@remix-run/react";
import { fetchJobPosts } from "~/utils/fetch_job_posts";
import styles from "~/styles/index.css";
import type { LoaderArgs, V2_MetaFunction } from "@remix-run/node";
import { BodyDiv } from "~/components/typography";
import { links as jobPostLinks } from "~/components/job_posts";
import { links as selectLinks } from "~/components/select";
import { links as buttonLinks } from "~/components/button";
import { links as featuredPostsLinks } from "~/components/featured_posts";
import { fetchBoard } from "~/utils/fetch_board";
import { fetchFeaturedPosts } from "~/utils/fetch_featured_posts";
import { fetchDepartments } from "~/utils/fetch_departments";
import { links as filtersLinks } from "~/shared/filters";
import { fetchOffices } from "~/utils/fetch_offices";
import { Logo, links as logoLinks } from "~/components/logo";
import { useBoardConfiguration } from "~/hooks/use_board_configuration";
import BoardContent, { links as boardContentLinks, MAX_PER_PAGE } from "~/components/board_content";
import useRecruitics from "~/hooks/use_recruitics";
import { withNamespace } from "~/utils/translation";
import ExternalNotifications, {
  links as externalNotificationsLinks } from
"~/components/external_notifications";
import { fetchBoardConfiguration } from "~/utils/fetch_board_configuration";
import { useTrackingParams } from "~/hooks/use_tracking_params";
import { fetchCustomFields } from "~/utils/fetch_custom_fields";
import { customFieldFiltersFromUrl } from "~/utils/custom_field_filters";
import { fetchRecentlyLiveDepartments } from "~/utils/fetch_recently_live_departments";
import classNames from "classnames";
import { Header } from "~/components/header";

export const meta: V2_MetaFunction = ({ data }) => {
  const t = withNamespace("board");
  return [
  {
    title: t("page_title", {
      companyName: data.board.name,
      interpolation: { escapeValue: false }
    })
  }];

};

export function links() {
  return [
  ...jobPostLinks(),
  ...boardContentLinks(),
  ...selectLinks(),
  ...filtersLinks(),
  ...logoLinks(),
  ...buttonLinks(),
  ...externalNotificationsLinks(),
  ...featuredPostsLinks(),
  { rel: "stylesheet", href: styles }];

}

export const loader = async ({ params, request }: LoaderArgs) => {
  const url = new URL(request.url);
  const urlToken = (params.url_token as string);

  const departmentIds = url.searchParams.getAll("departments[]") || undefined;
  const officeIds = url.searchParams.getAll("offices[]") || undefined;
  const keyword = url.searchParams.get("keyword") || undefined;
  const customFieldFilters = customFieldFiltersFromUrl(url);

  const { configuration, status } = await fetchBoardConfiguration({ urlToken });

  if (status === 404 || configuration === undefined) {
    throw new Response(null, { status: 404 });
  }

  const { display_department_hierarchy: displayDepartmentHierarchy } = configuration;

  const [
  { jobPosts, status: jobStatus },
  { featuredPosts },
  { board, status: boardStatus },
  { departments, status: departmentsStatus },
  { departments: recentlyLiveDepartments, status: recentlyLiveDepartmentsStatus },
  { offices, status: officesStatus },
  { customFields, status: customFieldsStatus }] =
  await Promise.all([
  fetchJobPosts({
    urlToken,
    content: true,
    page: url.searchParams.get("page") || undefined,
    count: MAX_PER_PAGE,
    departmentIds,
    officeIds,
    keyword,
    includeDepartment: true,
    customFieldFilters
  }),
  fetchFeaturedPosts({
    urlToken
  }),
  fetchBoard({ urlToken }),
  fetchDepartments({ urlToken, displayDepartmentHierarchy }),
  fetchRecentlyLiveDepartments({ urlToken }),
  fetchOffices({ urlToken }),
  fetchCustomFields({ urlToken })]
  );

  if (
  jobStatus === 404 ||
  boardStatus === 404 ||
  departmentsStatus === 404 ||
  recentlyLiveDepartmentsStatus === 404 ||
  officesStatus === 404 ||
  customFieldsStatus === 404)
  {
    throw new Response(null, { status: 404 });
  }

  if (board.redirect_to) {
    return redirect(board.redirect_to);
  }

  return json({
    jobPosts,
    featuredPosts,
    board,
    departments,
    recentlyLiveDepartments,
    offices,
    departmentIds,
    officeIds,
    customFieldFilters,
    keyword,
    urlToken,
    customFields
  });
};

export const handle = { i18n: ["board", "job_post"] };

export default function Index() {
  const {
    jobPosts,
    featuredPosts,
    board,
    departments,
    recentlyLiveDepartments,
    offices,
    departmentIds,
    officeIds,
    customFieldFilters,
    keyword,
    urlToken,
    customFields
  } = useLoaderData<typeof loader>();
  const {
    board_layout: boardLayoutConfiguration,
    allow_external_notifications: allowExternalNotifications,
    logo: logoConfiguration,
    filters: filterConfiguration,
    outside_label: outsideLabel,
    enable_recruitics: enableRecruitics,
    header_text: headerText
  } = useBoardConfiguration();
  const { withTrackingParams } = useTrackingParams();

  const departmentFiltered = departmentIds && departmentIds.length > 0;
  const officeFiltered = officeIds && officeIds.length > 0;
  const customFieldFiltered =
  customFieldFilters && Object.values(customFieldFilters).some((values) => values.length > 0);
  const filtered =
  officeFiltered || departmentFiltered || customFieldFiltered || !!keyword || jobPosts.page !== 1;
  const showExternalNotifications =
  allowExternalNotifications && recentlyLiveDepartments.length > 0;

  useRecruitics(enableRecruitics);

  return (
    <div className="index--content">
      <Logo
        url={logoConfiguration.url}
        href={logoConfiguration.href && withTrackingParams(logoConfiguration.href)}
        companyName={board.name} />

      <Header headerText={headerText} companyName={board.name} />
      <div
        className={classNames("index--header", { "index--header__no-description": !board.content })}>

        {board.content &&
        <div className="index--description">
            <BodyDiv>
              <span dangerouslySetInnerHTML={{ __html: board.content }}></span>
            </BodyDiv>
          </div>}

        {showExternalNotifications &&
        <ExternalNotifications urlToken={urlToken} companyName={board.name} />}

      </div>

      <BoardContent
        departments={departments}
        offices={offices}
        customFields={customFields}
        departmentIds={departmentIds}
        officeIds={officeIds}
        customFieldFilters={customFieldFilters}
        keyword={keyword}
        boardLayoutConfiguration={boardLayoutConfiguration}
        internalBoard={false}
        filterConfiguration={filterConfiguration}
        filtered={filtered}
        jobPosts={jobPosts}
        featuredPosts={featuredPosts}
        outsideLabel={outsideLabel} />

    </div>);

}